.line{
    display: block;
    padding: 2vw;
    color: $simplus-dark-blue;
    @include mobile-format(){
        padding: 6vw;
    }

    .msisdn {
        display: block;
        height: 4vw;
        @include mobile-format(){
            height: 10vw;
        }
        .num{
            display: inline-block;
            float: left;
            font-family: $simplus-tertiary-font-family;
            font-size: 2.2vw;
            @include mobile-format(){
                font-size: 8vw;
            }
        }
    
        .brand {
            display: inline-block;
            float: right;
            img {
                width: 5vw;
                margin-top: -0.5vw;
                @include mobile-format(){
                    width: 15vw;
                    margin-top: -2.5vw;
                }
            }
        }
        
    }

    h2 {
        padding-top: 1vw;
        font-size: 1.5vw;
        font-family: $simplus-secondary-font-family;
        @include mobile-format(){
            padding-top: 2vw;
            font-size: 3.5vw;
        }
    }

    h3 {
        font-size: 1.1vw;
        @include mobile-format(){
            font-size: 2.5vw;
        }
    }

    .switch_offer {
        position: relative;
        float: right;
        margin-top: -5vw;
        right: 0;
        button {
            background: $simplus-dark-blue;
            @include mobile-format(){
                font-size: 2vw;
            }
        }
        @include mobile-format(){
            margin-top: -13vw;
        }
    }

    .offer_price{
        text-align: right;
        font-family: $simplus-tertiary-font-family;
        font-size: 2vw;
        @include mobile-format(){
            font-size: 5vw;
        }
    }

    .offer_details{
        margin-bottom: 2vw;
        span {
            display: block;
            width: 100%;
            margin-top: 0.5vw;
            font-size: 1.1vw;
            @include mobile-format(){
                margin-top: 1vw;
                font-size: 2.5vw;
            }
        }
    }

    .sim {
        display: block;
        height: 5vw;
        margin-top: 2vw;
        @include mobile-format(){
            margin-top: 4vw;
            height: 6vw;
        }

        .title {
            display: inline-block;
            float: left;
            font-family: $simplus-secondary-font-family;
            font-size: 1.5vw;
            color: $simplus-orange-action;
            @include mobile-format(){
                font-size: 4vw;
            }
        }
        .num {
            display: inline-block;
            float: right;
            font-family: $simplus-tertiary-font-family;
            font-size: 1.5vw;
            @include mobile-format(){
                font-size: 4vw;
            }
        }
    }
}
