
/* colors */
$simplus-dark-blue: #283376;
$simplus-font-color-bg-dark-blue: #FFFFFF;
$simplus-font-color-bg-dark-blue2: #d2edff;
$simplus-orange-action: #e55f2b;

/* fonts */
$simplus-primary-font-family: 'Montserrat-Regular';
$simplus-secondary-font-family: 'Roboto-Regular';
$simplus-tertiary-font-family: 'BebasNeue-Regular';

/* margin */
$simplus-page-left-margin: 15vw;
$simplus-page-right-margin: 15vw;

$simplus-page-left-margin-mobile: 5vw;
$simplus-page-right-margin-mobile: 5vw;
