.cnt_date_picker{
    display: inline-block;
    float: right;
    margin-top: 2vw;
    margin-right: 3vw;
}

.conso_cnt{
    padding-top: 3vw;
    padding-bottom: 5vw;
    padding-left: 5vw;
    padding-right: 5vw;
    @include mobile-format(){
        padding-top: 14vw;
    }

    .title_conso{
        margin-top: 4vw;
        font-family: $simplus-secondary-font-family;
        font-size: 1.3vw;
        margin-bottom: 0.3vw;
        @include mobile-format(){
            font-size: 5vw !important;
            margin-top: 15vw;
        }
    }

    .subtitle_conso{
        font-size: 1vw;
        margin-bottom: 0.3vw;
        @include mobile-format(){
            font-size: 3.8vw;
            margin-bottom: 2.3vw;
        }
    }

    .conso_progress{
        height: 1.2vw;
        @include mobile-format(){
            height: 4.2vw;
        }
    }
}

.cnt_button_detail{
    padding-bottom: 3vw !important;
    .detail_btn{
        margin: auto !important;
        display: block !important;
        margin-bottom: 2rem !important;
    }
}


.cdr_tab_cnt{
    max-height: 440;
}

.pb-4 {
    padding-bottom: 4vw !important;
}