.param_menu{
    font-family: $simplus-primary-font-family !important;
    .button_ico {
        color: $simplus-orange-action;
        margin-right: 2vw;
    }
}

.disconnect_button{
    padding: 12px 16px;
    display: flex;
    flex-grow: 1;
    font-size: 1rem;
    font-family: $simplus-primary-font-family !important;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}

.change_email_expl{
    font-family: $simplus-primary-font-family !important;
    font-size: 0.9rem !important;
    text-align: center;
    padding: 1vw;
    margin-right: 2vw !important;
    display: block;
    width: 90%;
}

.btn_change {
    margin-top: 0.9vw !important;
}

.pb-5{
    padding-bottom: 5vw !important;
}

.form-tf{
    width: 100%;
}