.top-bar {
    background-color: $simplus-dark-blue;
    color: $simplus-font-color-bg-dark-blue;
    padding-left: $simplus-page-left-margin;
    padding-right: $simplus-page-right-margin;
    padding-top: 1vw;
    padding-bottom: 1vw;
    font-size: 1vw;
    height:1.4vw;
    @include mobile-format() {
        display: none;
    }
    
    .slogan {
        display: inline-block;
        float: left;
        @include mobile-format() {
            display: none;
          }
    }

    .support {
        display: inline-block;
        float: right;

        .phone_num {
            top: -0.5vw;
            left: 0.3vw;
            position: relative;
        }
        @include mobile-format() {
            display: none;
          }
    }

}