
.top-bar-modal-offer {
    background-color: $simplus-dark-blue !important;

}

.offer-expl {
    padding-top: 20px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 20px;

    color: $simplus-dark-blue !important;
    text-align: center;
    @include mobile-format(){
        font-size: 5vw !important;
    }
}

.offer-name{
    color: $simplus-dark-blue !important;
    display: inline-block;
    width: 70%;
    font-family: $simplus-tertiary-font-family !important;
    font-size: 1.5vw !important;
    text-align: left;
    @include mobile-format(){
        font-size: 4.5vw !important;
    }
}

.offer-amount{
    color: $simplus-dark-blue;
    font-family: $simplus-tertiary-font-family !important;
    float: right;
    width: 30%;
    text-align: right;
    display: inline-block;
    font-size: 2.5vw !important;
    @include mobile-format(){
        font-size: 5vw !important;
    }
}

.offer-details {
    display: inline-block;
    width: 70%;
    text-align: left;

    .offer_svc_cnt{
        display: block;
        width: 100%;
        font-family: $simplus-secondary-font-family !important;
        font-size: 1vw !important;
        @include mobile-format(){
            font-size: 3vw !important;
        }
    }
}


.offer-choose-btn {
    float: right;
    display: block;
    position: absolute;
    right: 1vw;
    margin-top: -3vw !important;
    &.no_display {
        display: none;
    }
    @include mobile-format(){
        margin-top: -13vw !important;
    }
}

.logo_simplus_offer{
    padding-top: 100px;
    width: 100px;
    display: block;
    margin: auto;
}

.offer_cnt {
    padding-bottom: 40px !important;
}

.overlay_curr_offer{
    opacity: 0.4;
}

.reason_text_elt{
    position: absolute;
    width: 100%;
    text-align: center;
    color: red;
}