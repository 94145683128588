.oldoffer-head{
    color: $simplus-dark-blue !important;
    font-family: $simplus-secondary-font-family !important;
    font-size: 1vw !important;
    text-align: center;
    @include mobile-format(){
        font-size: 3vw !important;
    }
}

.oldoffer-name{
    color: $simplus-dark-blue !important;
    font-family: $simplus-tertiary-font-family !important;
    font-size: 1.4vw !important;
    text-align: center;
    @include mobile-format(){
        font-size: 4vw !important;
    }
}

.arrow_down{
    display: block !important;
    margin-left: auto;
    margin-right: auto;
    width: 15vw !important;
    height: 15vw !important;
    fill: $simplus-dark-blue !important;
    @include mobile-format(){
        width: 25vw !important;
        height: 25vw !important;
    }
}

.newoffer-name{
    color: $simplus-dark-blue !important;
    font-family: $simplus-tertiary-font-family !important;
    font-size: 1.8vw !important;
    text-align: center;
    @include mobile-format(){
        font-size: 5vw !important;
    }
}

.newoffer-price{
    color: $simplus-dark-blue;
    font-family: $simplus-tertiary-font-family !important;
    text-align: center;
    font-size: 1.8vw !important;
    @include mobile-format(){
        font-size: 5vw !important;
    }
}

.newoff_conf{
    margin-top: 3vw !important;
    @include mobile-format(){
        margin-top: 10vw !important;
    }
}

.newoffer-change-ope-info{
    color: red;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
}