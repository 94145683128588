.bill_date{
    color:$simplus-orange-action;
    width: 20vw;
    @include mobile-format(){
        width: 30vw;
        font-size: 3.5vw !important;
    }
}

.unpaid_bill{
    color: red;
    width: 15vw;
    @include mobile-format(){
        width: 20vw;
        font-size: 3.5vw !important;
    }
}

.paid_bill {
    color: $simplus-dark-blue;
    width: 15vw;
    @include mobile-format(){
        width: 20vw;
        font-size: 3.5vw !important;
    }
}

.bill_amount {
    color: $simplus-dark-blue;
    width: 10vw;
    @include mobile-format(){
        width: 20vw;
        font-size: 3.5vw !important;
    }
}

.bill_msisdn{
    color: $simplus-dark-blue;
    font-family: $simplus-tertiary-font-family;
    width: 15vw;
    @include mobile-format(){
        display: none;
    }
}

.bill_offer {
    display: none;
    width: 20vw;
    @include mobile-format(){
        width: 40vw;
        font-size: 3.5vw !important;
    }
}

.bill_dwld{
    cursor: pointer;
    .ico_dwld{
        vertical-align: middle;
    }
}

.modal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.paper{
    background-color: #FFFFFF;
    border: 2px solid #000;
    padding: 16px 32px 24px;
}