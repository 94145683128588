.simplus_button{
    background: -webkit-linear-gradient(left, #dd4629 0%, #ec9c00 100%);
    background: linear-gradient(to right, #dd4629 0%, #ec9c00 100%);
    font-family: $simplus-secondary-font-family !important;
    margin-left: auto !important;
    margin-right: auto !important;
    display: block !important;
}

.pressed_button_loader{
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: block !important;
}