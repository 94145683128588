@font-face {
    font-family: 'BebasNeue-Regular';
    src: url(./assets/font/BebasNeue-Regular.eot);
    src: url(./assets/font/BebasNeue-Regular.eot?#iefix) format('embedded-opentype'),
         url(./assets/font/BebasNeue-Regular.otf) format('otf'),
         url(./assets/font/BebasNeue-Regular.svg#BebasNeue-Regular) format('svg'),
         url(./assets/font/BebasNeue-Regular.ttf) format('truetype'),
         url(./assets/font/BebasNeue-Regular.woff) format('woff'),
         url(./assets/font/BebasNeue-Regular.woff2) format('woff2');
}

  @font-face {
    font-family: 'Roboto-Regular';
    src: url(./assets/font/Roboto-Regular.eot);
    src: url(./assets/font/Roboto-Regular.eot?#iefix) format('embedded-opentype'),
         url(./assets/font/Roboto-Regular.otf) format('otf'),
         url(./assets/font/Roboto-Regular.svg#Roboto-Regular) format('svg'),
         url(./assets/font/Roboto-Regular.ttf) format('truetype'),
         url(./assets/font/Roboto-Regular.woff) format('woff'),
         url(./assets/font/Roboto-Regular.woff2) format('woff2');
  }
  
  @font-face {
    font-family: 'Montserrat-Regular';
    src: url(./assets/font/Montserrat-Regular.eot);
    src: url(./assets/font/Montserrat-Regular.eot?#iefix) format('embedded-opentype'),
         url(./assets/font/Montserrat-Regular.otf) format('otf'),
         url(./assets/font/Montserrat-Regular.svg#Montserrat-Regular) format('svg'),
         url(./assets/font/Montserrat-Regular.ttf) format('truetype'),
         url(./assets/font/Montserrat-Regular.woff) format('woff'),
         url(./assets/font/Montserrat-Regular.woff2) format('woff2');
  }
  
body {
    margin: 0;
    font-family: 'Montserrat-Regular';
    background-color: #F5F5F5;
    color: $simplus-dark-blue;
    background-color: $simplus-font-color-bg-dark-blue2;
}

.hidden_on_mobile{
  @include mobile-format() {
    display: none;
  }
}