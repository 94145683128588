.menu_img{
    width: 3vw;
    height: 3vw;
    @include mobile-format(){
        width: 9vw;
        height: 9vw;
    }
}

.logo_simplus{
    width:7vw;
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding-top:1vw;
    padding-bottom:1vw;   
    @include mobile-format(){
        padding-top:3vw;
        width:17vw;
    }
}