.cnt_account{
    padding:4vw;
    padding-top:2vw;

    .account_cat{
        font-family: $simplus-secondary-font-family;
        font-size: 1.5vw;
        color: $simplus-dark-blue;
        padding-top: 2vw;
        @include mobile-format(){
            font-size: 4.5vw;
        }
        span {
            top: -0.3vw;
            left: 0.2vw;
            position: relative;
            @include mobile-format(){
                top: -1.3vw;
                left: 1.2vw;
            }
        }
    }

    .acc_data{ 
        margin-top: 1vw;
        margin-left: 1vw;
        font-size: 1.2vw;
        font-family: $simplus-primary-font-family;
        @include mobile-format(){
            font-size: 3.2vw;
        }
    }

    .acc_data2{ 
        margin-left: 1vw;
    }

    .acc_inline_cnt{
        display: table;
        width: 100%;
        margin-top: 1vw;
        height: 2vw;
        @include mobile-format(){
            margin-top: 2vw;
        }
        .acc_inl_title{
            margin-left: 1vw;
            font-family: $simplus-secondary-font-family;
            display: inline-block;
            float: left;
            color: $simplus-orange-action;
        }
        .acc_inl_data{
            display: inline-block;
            float: right;
        }
    }

    .account_divider{
        margin-top: 2vw;
        @include mobile-format(){
            margin-top: 5vw;
            margin-bottom: 5vw;
        }
    }

    .btn_upd{
        text-align: right;
        color: $simplus-orange-action;
        font-family: $simplus-secondary-font-family;
        font-size: 1.5vw;
        margin-top: 1vw;
        display: inline-block;
        width: 100%;
        @include mobile-format(){
            margin-top: 4vw;
            font-size: 4.5vw;
        }
        a {
            color: $simplus-orange-action;
            float: right;
        }
    }
}

.button_ico{
    vertical-align: middle;
}

.mt-2{
    margin-top: 2vw !important;
    @include mobile-format(){
        margin-top: 6vw !important;
    }
}

.cursor_pointer{
    cursor: pointer;
}

.width_20 {
    width: 20vw !important;
    @include mobile-format(){
        width: 40vw !important;
    }
}

.width_51{
    width: 51% !important;
    @include mobile-format(){
        width: 91% !important;
    }
}