.page-logged {
    .homelogged_title{
        display: inline-block;
        background-color: #FFF;
        padding-top:2vw;
        padding-left: $simplus-page-left-margin;
        padding-right: $simplus-page-right-margin;
        width: 100vw;
        &_logo{
            display: inline-block;
            float: left;
            & > img {
                width: 9vw;
                margin-left: 3vw;
                margin-bottom: 1vw;
            }
        }

        &_text {
            display: inline-block;
            float: left;
            padding-left: 6vw;
            & > h1 {
                font-size: 2vw;

                @include mobile-format() {
                    font-size: 2.5vw;
                }
            }
        }
    }
    .content{
        margin-left: $simplus-page-left-margin;
        margin-right: $simplus-page-right-margin;
        margin-top: 1vw;
        @include mobile-format(){
            margin-left: $simplus-page-left-margin-mobile;
            margin-right: $simplus-page-right-margin-mobile;
            margin-top: 5vw;
        }
    }
}

.page_loader{
    margin-left: auto;
    margin-right: auto;
    display: block !important;
    padding-top: 5vw;
    padding-bottom: 5vw;
}

.selected_menu_item{
    background-color: $simplus-dark-blue !important;
    color: $simplus-font-color-bg-dark-blue2 !important;
}

.top-bar-mobile{
    display: none;
    @include mobile-format(){
        background-color: $simplus-dark-blue;
        color: $simplus-font-color-bg-dark-blue;
        padding-left: $simplus-page-left-margin-mobile;
        padding-right: $simplus-page-right-margin-mobile;
        padding-top: 1vw;
        padding-bottom: 1vw;
        font-size: 1vw;
        height: 14vw;
        display: block;
    }

    .mobile_head_menu{
        display: inline-block;
        float: left;
        width: 50vw;
        .menu_head_img{
            width: 12vw;
        }
        span{
            font-size: 6vw;
            margin-left: 14vw;
            margin-top: -9vw;
            display: block;
        }
    }

    .mobile_menu_icon {
    
        @include mobile-format(){
            display: inline-block;
            float: right;
        }
    
        .menu_icon{
            fill: #FFF;
            height:14vw;
            width: 14vw;
        }
    }
}
