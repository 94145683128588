.mabo-2{
    margin-bottom: 2vw !important;
}

.width-150 {
    width: 150px !important;
}

.width-200 {
    width: 200px !important;
}

.width-250 {
    width: 250px !important;
}



.mt-3 {
    margin-top: 3vw !important;
}

.mt-2 {
    margin-top: 2vw !important;
}