.faq_question {
    font-family: $simplus-primary-font-family !important;
    color: $simplus-orange-action;
    padding-left: 1vw;
    font-size: 1.2vw !important;
    @include mobile-format(){
        font-size: 3.5vw !important;
    }
}

.faq_response {
    font-family: $simplus-primary-font-family !important; 
    color: $simplus-dark-blue;
    padding-left: 1vw;
    font-size: 1vw !important;
    @include mobile-format(){
        font-size: 3.2vw !important;
    }
}