.login_form {
    padding-top:1vw;
    padding-bottom:2vw;
    padding-left: 3vw;
    padding-right: 3vw;
    h2 {
        font-family: $simplus-tertiary-font-family;
        text-align: center;
    }

    .form-tf {
        width: 100%;
        margin-bottom: 1.5vw !important;
        &.mb-2{
            margin-bottom: 3vw !important;
        }
    }

    p {
        text-align: center;
        font-size: 1vw;
    }

    .err_msg{
        color: red;
        text-align: center;
        font-size: 0.8vw;
        width: 100%;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: -0.5vw;
        margin-bottom: 0.5vw;
    }
}

.fpass {
    @include mobile-format() {
        font-size: 3.8vw;
        margin-top: 5vw !important;
        display: block;
    }
}