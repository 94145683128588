
.top-bar-modal-unpaid-bills {
    background-color: $simplus-dark-blue !important;

}

.unpaid-bill-expl {
    padding-top: 20px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 20px;

    color: $simplus-dark-blue !important;
    text-align: center;
}

.unpaid-bill-name{
    color: $simplus-dark-blue !important;
    display: inline-table;
}
.unpaid-bill-date{
    display: inline-table;
    padding-left: 20px;
}
.unpaid-bill-amount{
    color: red;
    display: inline-table;
    padding-left: 20px;
}
.unpaid-bill-status{
    color: red;
    font-size: 1vw !important;
}

.unpaid-bill-total{
    padding-top: 20px;
    color: red;
    text-align: center;
}

.unpaid-bill-write-info{
    text-align: center;
    padding-top: 20px;
}

.logo_cb_unpaid_bills{
    width: 200px;
    display: block;
    margin: auto;
    padding-top: 20px;
    padding-bottom: 20px;
}

.logo_simplus_unpaid_bills{
    padding-top: 100px;
    width: 100px;
    display: block;
    margin: auto;
}

.unpaid_form{
    display: block !important;
    width: 250px !important;
    margin-left: auto !important;
    margin-right: auto !important;

    > div {
        width: 100% !important;
    }
}

.pay_button{
    margin-top: 30px !important;
}

.unpaid_cnt {
    padding-bottom: 40px !important;
}