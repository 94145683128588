.main-content {
    margin-left: $simplus-page-left-margin;
    margin-right: $simplus-page-right-margin;
    margin-top:3vw;
    @include mobile-format() {
        margin-top:5vw;
        margin-left: $simplus-page-left-margin-mobile;
        margin-right: $simplus-page-right-margin-mobile;
    }

    .home_title{
        display: inline-block;

        &_logo{
            display: inline-block;
            float: left;
            & > img {
                width: 9vw;
                @include mobile-format() {
                    width: 25vw;
                }
            }
        }

        &_text {
            display: inline-block;
            float: left;
            padding-left: 2vw;
            & > h1 {
                font-size: 2vw;

                @include mobile-format() {
                    font-size: 5.5vw;
                }
            }
        }
    }

    .home_content{
        margin-top:4vw;
    }

    .old_cc_cnt{
        width: 100%;
        margin-top: 1vw;
        a {
            text-decoration: underline;
            text-align: center;
            width: 100%;
            display: block;
            color: #000;
            font-size: 1vw;
        }
    }
}