.bottom-bar {
    color: $simplus-dark-blue;
    text-align: center;
    font-size: 0.8vw;
    margin-top: 3vw;
    margin-bottom: 1vw;
    display: inline-block;
    width: 100vw;
    @include mobile-format(){
        font-size: 3vw;
    }
}

.stores{
    display: block;
    width: 40vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4vw;
    @include mobile-format(){
        width: 90vw;
    }
    .appstore{
        display: inline-block;
        float: left;
        width: 20vw;
        @include mobile-format(){
            width: 40vw;
        }
        img {
            margin-left: 6.5vw;
            width: 13vw;
            @include mobile-format(){
                width: 35vw;
                margin-left: 5vw;
            }
        }
    }
    .googleplay{
        display: inline-block;
        float: left;
        width: 20vw;
        @include mobile-format(){
            width: 40vw;
        }
        img {
            margin-left: 0.5vw;
            width: 13vw;
            @include mobile-format(){
                width: 35vw;
                margin-left: 5vw;
            }
        }
    }
}